body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* GENERAL CSS */
body {
  background-color: #f5f5f5;
}
.card {
  padding: 16px;
}
button {
  border: none;
  background: transparent;
  border-radius: 4px;
}
.row > *:not([class*="col-"]) {
  width: auto;
}
/* /GENERAL CSS */
.iframe-section label {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.iframe-section .iframe-form-cover label span {
  min-width: 100px;
  width: 100px;
}
